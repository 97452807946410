import { HttpBackend, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth0Client, createAuth0Client, RedirectLoginResult } from '@auth0/auth0-spa-js';
import { AuthorizationParams, RedirectLoginOptions } from '@auth0/auth0-spa-js/src/global';
import { captureException } from '@sentry/angular-ivy';
import { combineLatest, from, Observable, of as observableOf } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { WithRequired } from '@app/utils/types';
import { WindowService } from '@app/utils/window.service';

import { ApiHeaderService } from './api-header.service';
import { Auth0AnalyticsService } from './auth0-analytics.service';
import { ConfigService } from './config.service';
import { FeatureFlags, FeatureFlagVariants } from './feature-flags/feature-flags';
import { LaunchDarklyService } from './feature-flags/launchdarkly.service';
import { RegistrationAccessTokenService } from './registration-access-token.service';
import { RetrieveAccessTokenGraphQLService } from './retrieve-access-token-graphql.service';

export enum SocialConnection {
  Amazon = 'login-with-amazon',
}

export interface Auth0AuthParams extends AuthorizationParams {
  connection?: SocialConnection;
  user_blocked?: string;
}

@Injectable({
  providedIn: 'root',
})
export class Auth0ClientService implements HttpInterceptor {
  #auth0Client: Auth0Client;
  #accessTokenFromNonce: string | null | undefined;
  private readonly redirectUri: string;
  private readonly redirectCallbackQueryParamKeys = ['code', 'state'];
  private readonly nonceQueryParamKey = 'nonce';

  constructor(
    private configService: ConfigService,
    private windowService: WindowService,
    private httpBackend: HttpBackend,
    private launchDarklyService: LaunchDarklyService,
    private router: Router,
    private registrationAccessTokenService: RegistrationAccessTokenService,
    private apiHeaderService: ApiHeaderService,
  ) {
    this.redirectUri = this.windowService.getLocationOrigin();
  }

  static initializeAuth0Client(
    auth0ClientService: Auth0ClientService,
    auth0AnalyticsService: Auth0AnalyticsService,
    windowService: WindowService,
    retrieveAccessTokenGraphQLService: RetrieveAccessTokenGraphQLService,
  ): () => Observable<unknown> {
    return () =>
      auth0ClientService.initialize().pipe(
        switchMap(() => {
          const params: URLSearchParams = windowService.getUrlQueryParams();
          const isRedirectedFromLogin: boolean = auth0ClientService.redirectCallbackQueryParamKeys.every(key =>
            params.has(key),
          );
          const nonce: string | null = params.get(auth0ClientService.nonceQueryParamKey);

          if (isRedirectedFromLogin) {
            auth0AnalyticsService.trackLoginCompleted();
            return auth0ClientService.handleRedirectCallback$();
          } else if (nonce) {
            return auth0ClientService.retrieveAccessToken$(retrieveAccessTokenGraphQLService, nonce);
          } else {
            return observableOf(null);
          }
        }),
      );
  }

  linkWithSocialConnection(customAuthorizationParams: WithRequired<Auth0AuthParams, 'connection'>): void {
    const authorizationParams: AuthorizationParams = {
      ...customAuthorizationParams,
      redirect_uri: this.redirectUri,
    };

    const options: WithRequired<RedirectLoginOptions, 'authorizationParams'> = { authorizationParams };

    this.#getToken$()
      .pipe(
        switchMap(token => {
          if (!token) {
            throw new Error('cannot embed token because we do not have a token');
          }

          return observableOf(token);
        }),
      )
      .subscribe({
        next: token => {
          options.authorizationParams.current_token = token;
          this.#auth0Client.loginWithRedirect(options);
        },
      });
  }

  login({
    fragment,
    customAuthorizationParams,
  }: { fragment?: string; customAuthorizationParams?: Auth0AuthParams } = {}): void {
    this.apiHeaderService.revokeAccessToken();
    const authorizationParams: AuthorizationParams = {
      ...customAuthorizationParams,
      redirect_uri: this.redirectUri,
    };

    const options: RedirectLoginOptions = { authorizationParams };

    if (fragment && fragment.length > 0) {
      options.fragment = fragment;
    }

    this.#auth0Client.loginWithRedirect(options);
  }

  logout(): void {
    this.apiHeaderService.revokeAccessToken();
    this.registrationAccessTokenService.removeToken();

    this.#auth0Client.logout({
      logoutParams: { returnTo: this.redirectUri },
    });
  }

  isAuthenticated$(): Observable<boolean> {
    return this.#getToken$().pipe(map(Boolean));
  }

  intercept<T>(request: HttpRequest<T>, nextInterceptor: HttpHandler): Observable<HttpEvent<T>> {
    return this.launchDarklyService.featureFlag$(FeatureFlags.NO_COOKIE_FOR_YOU, false).pipe(
      switchMap(enabled => {
        if (!enabled || !request.url.startsWith(this.configService.json.myoneServer)) {
          return observableOf(null);
        }

        return this.#getToken$();
      }),
      switchMap(token => {
        if (token) {
          const authenticatedRequest = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`),
          });

          return this.httpBackend.handle(authenticatedRequest);
        } else {
          return nextInterceptor.handle(request);
        }
      }),
    );
  }

  setTokenInApiHeaderService$(): Observable<boolean> {
    return this.#getToken$().pipe(
      tap(token => (token ? this.apiHeaderService.setAccessToken(token) : this.apiHeaderService.revokeAccessToken())),
      map(token => !!token),
    );
  }

  persistToken(token: string | null) {
    if (token) {
      this.apiHeaderService.setAccessToken(token);
      this.registrationAccessTokenService.persistToken(token);
    } else {
      this.apiHeaderService.revokeAccessToken();
    }
  }

  #getToken$(): Observable<string | null> {
    const registrationAccessToken: string | null = this.registrationAccessTokenService.retrieveToken();
    if (registrationAccessToken) {
      return observableOf(registrationAccessToken);
    } else if (this.#accessTokenFromNonce) {
      return observableOf(this.#accessTokenFromNonce);
    } else {
      return this.#getTokenFromAuth0$();
    }
  }

  #getTokenFromAuth0$(): Observable<string | null> {
    return from(this.#auth0Client.isAuthenticated()).pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          return observableOf(null);
        }

        return this.#auth0Client.getTokenSilently();
      }),
      catchError(error => {
        captureException(error);
        return observableOf(null);
      }),
    );
  }

  private handleRedirectCallback$(): Promise<RedirectLoginResult> {
    return this.#auth0Client
      .handleRedirectCallback()
      .catch(() => ({}))
      .finally(() => this.removeQueryParamsFromPageUrl(this.redirectCallbackQueryParamKeys));
  }

  private removeQueryParamsFromPageUrl(paramKeysToDelete: string[]): void {
    const params: URLSearchParams = this.windowService.getUrlQueryParams();
    paramKeysToDelete.forEach(key => params.delete(key));
    const queryParams: string = params.toString();

    let url: string = this.windowService.getLocationPathname();
    if (queryParams) {
      url = `${url}?${queryParams}`;
    }

    this.router.navigateByUrl(url, { replaceUrl: true });
  }

  private initialize(): Observable<unknown> {
    const {
      auth0: { audience, clientId, domain },
      myoneServer,
    } = this.configService.json;

    return combineLatest([
      this.launchDarklyService
        .featureFlag$<FeatureFlagVariants>(FeatureFlags.LOGIN_WITH_AMAZON, FeatureFlagVariants.ON_VARIANT)
        .pipe(map(flag => flag === FeatureFlagVariants.ON_VARIANT)),
      this.launchDarklyService
        .featureFlag$<FeatureFlagVariants>(FeatureFlags.REGISTER_WITH_AMAZON, FeatureFlagVariants.OFF)
        .pipe(map(flag => flag === FeatureFlagVariants.ON_VARIANT)),
    ]).pipe(
      switchMap(([loginFlag, registerFlag]) =>
        from(
          createAuth0Client({
            // Along with creating a client, fetches an access token if the user's Auth0 session is still good.
            domain,
            clientId,
            authorizationParams: {
              audience,
              redirect_uri: this.redirectUri,
              patient_host: myoneServer,
              login_with_amazon: loginFlag,
              register_with_amazon: registerFlag,
            },
          }),
        ),
      ),
      tap(client => (this.#auth0Client = client)),
      map(() => null),
      take(1),
    );
  }

  private retrieveAccessToken$(
    retrieveAccessTokenGraphQLService: RetrieveAccessTokenGraphQLService,
    nonce: string,
  ): Observable<null> {
    return retrieveAccessTokenGraphQLService.fetch({ nonce }).pipe(
      map(({ data }) => data.accessToken),
      tap(accessToken => (this.#accessTokenFromNonce = accessToken)),
      map(() => null),
      catchError(() => observableOf(null)),
      finalize(() => this.removeQueryParamsFromPageUrl([this.nonceQueryParamKey])),
    );
  }
}
