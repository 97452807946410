import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { AppointmentType } from './__generated__/appointment-type-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class AppointmentTypeGraphQL extends Query<AppointmentType> {
  document = gql`
    query AppointmentType($id: ID!, $serviceAreaId: ID) {
      appointmentTypeForPatient(id: $id) {
        id
        name
        displayName
        waitlistEnabled(serviceAreaId: $serviceAreaId)
        isBillable
        appointmentSeries {
          id
          name
          interval
          length
        }
      }
    }
  `;
}
