import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'om-svg-message',
  templateUrl: './svg-message.component.html',
})
export class SvgMessageComponent implements OnInit {
  @Input() stroke = 'black';
  @Input() width = '26';
  @Input() height = '24';

  constructor() {}

  ngOnInit() {}
}
