import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

import { AppointmentBookingStateService } from '@app/appointment/appointment-booking-state-service';
import { AppointmentBookingState } from '@app/appointment/appointment-booking-state-service/appointment-booking-state';
import { AppointmentRoutingStateService } from '@app/appointment/appointment-routing-state-service/appointment-routing-state.service';
import { AppointmentInventory } from '@app/appointment/provider-inventories';
import { AppointmentAnalyticsBaseService } from '@app/core/appointment-analytics-base.service';

import { AbstractConfirmationModalActionService } from './abstract-confirmation-modal-action.service';

@Component({
  selector: 'om-base-confirmation-modal-action',
  templateUrl: './base-confirmation-modal-action.component.html',
  styleUrls: ['./base-confirmation-modal-action.component.scss'],
})
export class BaseConfirmationModalActionComponent implements OnInit {
  @Input() selectedInventory: AppointmentInventory;
  @Input() analyticsService: AppointmentAnalyticsBaseService;
  @Input() canSubmit: boolean;
  @Input() isAppointmentConfirmationFullPageEnabled: boolean;
  @Output() modalClosed = new EventEmitter();
  @Output() showErrorModal = new EventEmitter<Error>();
  @Output() displayError = new EventEmitter();

  bookingState: AppointmentBookingState;
  confirming: boolean;
  phoneNumberFormValid: boolean;
  confirmationCTA: string;
  confirmingCTA: string;

  constructor(
    private bookingStateService: AppointmentBookingStateService,
    private router: Router,
    private routingStateService: AppointmentRoutingStateService,
    protected appointmentConfirmationModalService: AbstractConfirmationModalActionService,
  ) {}

  ngOnInit() {
    this.confirming = false;
    this.bookingState = this.bookingStateService.getAppointmentBookingState();
    this.confirmationCTA = this.appointmentConfirmationModalService.getConfirmationCTA(
      this.isAppointmentConfirmationFullPageEnabled,
    );
    this.confirmingCTA = this.appointmentConfirmationModalService.getConfirmingCTA(
      this.isAppointmentConfirmationFullPageEnabled,
    );
  }

  modalClose() {
    this.modalClosed.emit();
  }

  confirmAppointment() {
    if (!this.canSubmit) {
      this.displayError.emit();
      return;
    }

    this.confirming = true;
    this.submit();
  }

  protected submit() {
    const onNext = (appointmentId: string | number): void => {
      const source = this.getSource();
      const queryParams = this.bookingState.appointmentType.remote
        ? { source, timezone: this.selectedInventory.timezone }
        : { source };
      this.router.navigate([`appointments/${appointmentId}/confirmation`], { queryParams, replaceUrl: true });

      this.bookingStateService.setAppointmentBookingState(new AppointmentBookingState());
      this.routingStateService.resetAppointmentRoutingState();
      this.modalClosed.emit();
    };

    const onError = () => {
      this.analyticsService.bookingFailed();
      this.modalClosed.emit();
    };

    this.appointmentConfirmationModalService
      .executeAppointmentAction$(this.selectedInventory)
      .pipe(
        tap(result => {
          if (!result.success) {
            throw new Error(result.mutationErrors.map(errors => errors.messages.join(', ')).join(', '));
          }
        }),
      )
      .subscribe({
        next: result => {
          const appointmentId = result.appointment.id;
          this.analyticsService.appointmentBooked(
            +appointmentId,
            this.selectedInventory.id,
            +result.appointment.appointmentType.id,
          );

          onNext(appointmentId);
        },
        error: error => {
          onError();
          this.showErrorModal.emit(error);
        },
      });
  }

  private getSource() {
    return (
      this.bookingState.getSource() ||
      `Appointment Selected ${this.isAppointmentConfirmationFullPageEnabled ? 'Page' : 'Modal'}`
    );
  }
}
