import { Injectable } from '@angular/core';
import { gql, Query } from 'apollo-angular';

import { LatestMembershipEvent } from '@app/shared/__generated__/latest-membership-event-graphql.service.types';

@Injectable()
export class LatestMembershipEventGraphQLService extends Query<LatestMembershipEvent> {
  document = gql`
    query LatestMembershipEvent {
      membership {
        id
        latestEvent {
          id
          eventTypeName
          reason
        }
      }
    }
  `;
}
