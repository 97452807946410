import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { LinksService } from '@app/core/links.service';
import { LOGIN_WITH_AMAZON_MP_FLOW_VERSION } from '@app/core/mixpanel.constants';
import { RegistrationErrorCodes } from '@app/registration/registration-error/registration-error.component';
import { HORNBILL_ANALYTICS_FLOW_VERSION_PARAM_KEY } from '@app/shared/hornbill-params';
import { WindowService } from '@app/utils/window.service';

import { AuthService } from './auth.service';

export const UNAUTHORIZED_ERROR = 'unauthorized';
export const USER_BLOCKED_ERROR_DESCRIPTION = 'user is blocked';

@Injectable({
  providedIn: 'root',
})
export class Auth0ErrorGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private linksService: LinksService,
    private attemptedPathService: AttemptedPathService,
    private windowService: WindowService,
  ) {}

  private readonly USER_IS_BLOCKED_ERROR_CODE = 'userIsBlocked';

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const urlQueryParams = this.windowService.getUrlQueryParams();
    const rawErrorDescription = urlQueryParams.get('error_description');
    const error = urlQueryParams.get('error');

    if (!rawErrorDescription) {
      return true;
    }

    let errorCode: string;

    type CustomAuth0ErrorDescription = Record<'claim_code' | 'errorCode', string>;
    let customAuth0ErrorDescription: CustomAuth0ErrorDescription = <CustomAuth0ErrorDescription>{};

    try {
      customAuth0ErrorDescription = JSON.parse(decodeURIComponent(rawErrorDescription));
      errorCode = customAuth0ErrorDescription.errorCode;
    } catch (e) {
      if (error === UNAUTHORIZED_ERROR && rawErrorDescription === USER_BLOCKED_ERROR_DESCRIPTION) {
        errorCode = this.USER_IS_BLOCKED_ERROR_CODE;
      } else {
        throw e;
      }
    }

    let queryParams: string;

    switch (errorCode) {
      case RegistrationErrorCodes.LoginWithAmazonEnrollmentDefault:
        const { claim_code: claimCode } = customAuth0ErrorDescription;
        queryParams = new URLSearchParams({
          claim_code: claimCode,
          errorCode,
          [HORNBILL_ANALYTICS_FLOW_VERSION_PARAM_KEY]: LOGIN_WITH_AMAZON_MP_FLOW_VERSION,
        }).toString();

        this.authService.logout(`${this.linksService.registrationError}?${queryParams}`);
        break;
      case RegistrationErrorCodes.DuplicateExternalId:
        queryParams = new URLSearchParams({
          errorCode,
          [HORNBILL_ANALYTICS_FLOW_VERSION_PARAM_KEY]: LOGIN_WITH_AMAZON_MP_FLOW_VERSION,
        }).toString();

        this.authService.logout(`${this.linksService.registrationError}?${queryParams}`);
        break;
      case 'userIsBlocked':
        const { url } = state;
        this.attemptedPathService.setAttemptedPath(state.url);
        this.authService.goLogin({
          path: url,
          customAuthorizationParams: { user_blocked: 'true' },
        });

        break;
      default:
        throw new Error(`Unknown error code: ${errorCode}`);
    }

    return false;
  }
}
