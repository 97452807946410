import { CommonModule } from '@angular/common';
import { Component, Directive, Input, NgModule, TemplateRef, ViewChild } from '@angular/core';
import { Params } from '@angular/router';

import { TrackLinkFunction } from '@app/utils/track-link.directive';

import { OmguiButtonModule } from '../omgui-button/omgui-button.component';

export enum InteractiveCardVariant {
  Standard = 'standard_variant',
  Banner = 'banner_variant',
}

export enum InteractiveCardTheme {
  Standard = 'standard',
  AmazonPrimeBlue = 'amazon_prime_blue',
}

export interface OmguiButtonParams {
  internalLink?: string;
  externalLink?: string;
  newTab?: boolean;
  queryParams?: Params;
  trackLink?: TrackLinkFunction;
}

@Directive({
  selector: '[omgui-interactive-card]',
})
export class OmguiInteractiveCardDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'omgui-interactive-card',
  templateUrl: './omgui-interactive-card.component.html',
  styleUrls: ['./omgui-interactive-card.component.scss'],
})
export class OmguiInteractiveCardComponent {
  readonly OmguiInteractiveCardVariant = InteractiveCardVariant;

  @Input() title: String;
  @Input() variant: String;
  @Input() buttonParams: OmguiButtonParams = {};
  @Input() bannerPicture: string;
  @Input() headingLevel = 6;
  @Input() theme: InteractiveCardTheme = InteractiveCardTheme.Standard;
  @ViewChild(OmguiInteractiveCardDirective, { static: true })
  OmguiInteractiveCardDirective: OmguiInteractiveCardDirective;

  protected classThemeMap = {
    [InteractiveCardTheme.Standard]: '',
    [InteractiveCardTheme.AmazonPrimeBlue]: 'theme-amazon-prime-blue',
  };
}

@NgModule({
  declarations: [OmguiInteractiveCardComponent, OmguiInteractiveCardDirective],
  imports: [CommonModule, OmguiButtonModule],
  exports: [OmguiInteractiveCardComponent, OmguiInteractiveCardDirective],
})
export class OmguiInteractiveCardModule {}
