import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import get from 'lodash-es/get';

@Injectable()
export class NativeAppService {
  private readonly trueValue = 'true';
  private _isWebView: boolean;

  isWebView(route?: ActivatedRoute): boolean {
    // Memoizes webView so it is only set once per load
    this._isWebView ??= route?.snapshot?.queryParams?.is_web_view === this.trueValue;
    return this._isWebView;
  }

  postMessageToWebkitWebView(message: { action: string }) {
    if (get(window, ['webkit', 'messageHandlers', 'callbackHandler', 'postMessage'])) {
      (<any>window).webkit.messageHandlers.callbackHandler.postMessage(message);
    }
  }

  invokeAndroidWindowMethod(method: string) {
    if (get(window, ['Android', method])) {
      (<any>window).Android[method]();
    }
  }

  invokeAndroidAndiOS(action: string) {
    this.postMessageToWebkitWebView({ action: action });
    this.invokeAndroidWindowMethod(action);
  }
}

export const NativeAppActions = {
  CLOSE_APP: 'closeApp',
  CLOSE_WEBVIEW: 'closeWebView',
  HIR_AUTHORIZATION_SUCESS: 'hirAuthorizationSuccess',
  SURVEY_FINISHED: 'surveyFinished',
  TASK_FINISHED: 'taskFinished',

  GO_HEALTH_SURVEY: 'goHealthSurvey',
  GO_HOME: 'goHome',
  GO_BOOK_APPOINTMENT: 'goBookAppointment',
  GO_SELECT_PROVIDER: 'goSelectProvider',
  GO_MEMBERSHIP_SETTINGS: 'goMembershipSettings',
  GO_MESSAGES: 'goMessages',
  GO_GET_CARE_GCN: 'goGetCareGcn',
};
