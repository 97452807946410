<ng-container *ngIf="showPrimeSubscriptionUpsell$ | async">
  <div data-cy="prime-subscription-upsell-container" class="mt-2">
    <omgui-banner type="InfoLight" [dismissible]="false" data-cy="prepaid-invite-child-header-content">
      <div class="d-flex align-items-center">
        <om-svg-info class="me-2"></om-svg-info>
        <div class="mx-2 text-start">
          <p class="mb-0">
            If you are an Amazon Prime member,
            <a
              [omTrackLink]="trackBuyOnAmazon"
              href="{{ purchaseMembershipOnAmazonUrl }}"
              data-cy="prime-membership-link"
              aria-describedby="prime-membership-description"
              class="text-nowrap"
              >renew on Amazon</a
            >
            instead for $9/mo or $99/year
          </p>
        </div>
      </div>
    </omgui-banner>
  </div>
</ng-container>
