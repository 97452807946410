<svg [attr.width]="width" [attr.height]="height" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M22.728 21H3.27314C2.01829 21 1 19.9987 1 18.7635V5.2365C1 4.00125 2.01829 3 3.27314 3H22.728C23.9829 3 25 4.00125 25 5.2365V18.7635C25 19.9987 23.9829 21 22.728 21V21Z"
    [attr.stroke]="stroke"
    stroke-width="1.5"
  />
  <path
    d="M1 7L12.393 15.703C12.7514 15.9767 13.2486 15.9767 13.607 15.703L25 7"
    [attr.stroke]="stroke"
    stroke-width="1.5"
  />
</svg>
