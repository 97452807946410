import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LinksService } from '@app/core/links.service';
import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';
import { MembershipAnalyticsService } from '@app/membership/membership-analytics.service';

import { MembershipExpirationHelper } from '../membership-expiration-helper';

@Component({
  selector: 'om-expired-membership-banner',
  templateUrl: './expired-membership-banner.component.html',
  styleUrls: ['./expired-membership-banner.component.scss'],
})
export class ExpiredMembershipBannerComponent implements OnInit, OnDestroy {
  path: string;
  membership: Membership;
  formattedExpiryString: string;
  expirationDate: string;
  renewalLink = this.links.renewalOptions;
  trackExpiredMembershipBannerClicked = this.analytics.expiredMembershipBannerClicked.bind(this.analytics);
  trackExpiringB2bMembershipBannerClicked = this.analytics.expiringB2bMembershipBannerClicked.bind(this.analytics);
  trackReactivateMembershipBannerClicked = this.analytics.reactivateMembershipBannerClicked.bind(this.analytics);
  trackInvalidCCMembershipBannerClicked = this.analytics.invalidCCMembershipBannerClicked.bind(this.analytics);

  private closeSubscriptions = new Subject<void>();

  constructor(
    private membershipService: MembershipService,
    private analytics: MembershipAnalyticsService,
    private featureFlagSelectors: FeatureFlagSelectors,
    private links: LinksService,
    private router: Router,
  ) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(takeUntil(this.closeSubscriptions))
      .subscribe((_: NavigationEnd) => (this.path = router.url));
  }

  ngOnInit() {
    this.membershipService.membership$.pipe(takeUntil(this.closeSubscriptions)).subscribe((membership: Membership) => {
      this.membership = membership;
      const membershipExpirationHelper = new MembershipExpirationHelper(this.membership);
      this.formattedExpiryString = membershipExpirationHelper.formattedExpiry();
      const isTrial = this.membership.trialUntil || false;
      this.expirationDate = membershipExpirationHelper.expirationDateString();
      if (isTrial) {
        this.expirationDate = membershipExpirationHelper.freeTrialExpirationDateString();
      }
      this.setRenewalLink();
    });

    this.membershipService.getMembership();
  }

  canDisplayExpiredBanner() {
    return (
      this.path !== '/membership/renew/consumer' &&
      this.path !== '/membership/renew/enterprise' &&
      this.path !== '/membership/renew/omnow' &&
      this.path !== '/membership/renewal-options' &&
      this.path !== '/membership/settings'
    );
  }

  canDisplayAutoRenewBanner() {
    return this.path !== '/membership/settings';
  }

  // When the feature is deemed stable, remove this method & call to it (so it falls back to default set at var init)
  // The default is what we want going forward, but until the flag turned on - set link destination to "old" location.
  setRenewalLink() {
    this.featureFlagSelectors.getFeatureFlag(FeatureFlags.HORNBILL_SHOW_RENEWAL_OPTIONS, false).subscribe({
      next: active => {
        if (!active) {
          if (this.membership.isSelfPaid()) {
            this.renewalLink = this.links.membershipRenewConsumer;
          }
          if (this.membership.isB2b()) {
            this.renewalLink = this.links.membershipRenewEnterprise;
          }
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.closeSubscriptions.next();
    this.closeSubscriptions.complete();
  }
}
