import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigService } from '@app/core/config.service';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { MembershipAnalyticsService } from '@app/membership/membership-analytics.service';
import { appendQueryParamsToPath } from '@app/utils/link.utils';

@Component({
  selector: 'om-prime-subscription-upsell',
  templateUrl: './prime-subscription-upsell.component.html',
  styleUrls: ['./prime-subscription-upsell.component.scss'],
})
export class PrimeSubscriptionUpsellComponent implements OnInit {
  trackBuyOnAmazon = this.analyticsService.settingsConvertToHornbill.bind(this.analyticsService);
  showPrimeSubscriptionUpsell$: Observable<boolean>;
  protected purchaseMembershipOnAmazonUrl: string;

  constructor(
    private analyticsService: MembershipAnalyticsService,
    private config: ConfigService,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.purchaseMembershipOnAmazonUrl = appendQueryParamsToPath(this.config.json.amazonUrls.purchaseMembership, {
      nodl: '0',
    });
    this.showPrimeSubscriptionUpsell$ = this.launchDarklyService.featureFlag$(
      FeatureFlags.HORNBILL_SHOW_UPSELL_NOTICE,
      false,
    );
  }
}
